<script setup>
import { 
  ref, toRef, 
  inject, watch, nextTick,
  getCurrentInstance
} from "vue";

const appDlgLayers = inject('appDlgLayers');

const emit = defineEmits([
  "update:visible",
  "enter-animationend", 
  "leave-animationend",
]);

const props = defineProps({
  visible: { type: Boolean, default: false },
  animation: { default: 'pulldown', type: String},
  fullHeigth: { default: true, type: Boolean },
  transparent: { default: false, type: Boolean },
});

const dlgTrgt = ref(null);
const panel = ref(null);
const panelVisible = ref(!appDlgLayers);


const enterAnimEndHandlers = [];
function onEnterAnimationEnd(event){
  let handler;
  while(handler = enterAnimEndHandlers.shift()){
    handler(event);
  };
}

const leaveAnimEndHandlers = [];
function onLeaveAnimationEnd(event){
  let handler;
  while(handler = leaveAnimEndHandlers.shift()){
    handler(event);
  };
}


let instance =  getCurrentInstance();

watch( toRef(props, 'visible'), async () => {
  if(!props.visible){
    panelVisible.value = false;
    dlgTrgt.value = null;
  } else if(props.visible && !panelVisible.value){
    if(appDlgLayers?.value){
      const animationDone = new Promise( (resolve, reject) => {
        enterAnimEndHandlers.push(resolve);
        setTimeout( resolve, 800 ); //force uncoditional end of animation after 0.8s
      });

      const lrId = '#'+await appDlgLayers.value.pushInstance(
        animationDone,
        instance
      );
      dlgTrgt.value = lrId;
      emit('enter-animationend');
    }
    nextTick(() => {
      panel.value?.clearKeys();
      panelVisible.value = true;
    });
  } 
});


async function close(opt = {}){
  if(appDlgLayers?.value){
    if( opt.noAnimation ){
      //panelVisible.value = false;
      appDlgLayers?.value?.pop();
    } else {
      const animationDone = new Promise((resolve, reject) => {
        leaveAnimEndHandlers.push(resolve);
        setTimeout( resolve, 800 ); //force uncoditional end of animation after 0.8s
      });
      panelVisible.value = false;
      await appDlgLayers?.value?.pop(animationDone);
      emit('leave-animationend');
    }
    dlgTrgt.value = false;
    emit( "update:visible", false );
  }
}

defineExpose({ close });
</script>

<template>
  <teleport v-if="visible" :to="dlgTrgt" :disabled="!dlgTrgt">
    <div class="soft-key-dlg" >
      <div class="side-menu left">
        <slot name="menu-left" />
      </div>

      <div class="side-menu right">
        <slot name="menu-right" />
      </div>

      <transition 
        name="dlg-panel"
        @after-enter="onEnterAnimationEnd"
        @enter-canceled="onEnterAnimationEnd"
        @after-leave="onLeaveAnimationEnd"
        @leave-canceled="onLeaveAnimationEnd"
      >
        <div ref="panel"
          v-if="panelVisible"
          class="dlg-panel noselect"
          :class="{ 
            'dlg-panel-full-height': fullHeigth, 
            'dlg-panel-full-transparent': transparent,
            [`${animation}-animation`]: true,
          }"
        >
          <slot />
        </div>
      </transition>
    </div>
  </teleport>
</template>

<style lang="css">

.soft-key-dlg:has(.dlg-panel-enter-active),
.soft-key-dlg:has(.dlg-panel-leave-active) {
  height: 100%;
  overflow: hidden;
}

.dlg-panel-enter-active,
.dlg-panel-leave-active {
  transition: 
    transform var(--app-control-transition-time) ease-in,
    opacity var(--app-control-transition-time) ease-in;
  /*transition: transform  55s linear, opacity 55s linear;*/
}

.dlg-panel-enter-from,
.dlg-panel-leave-to {
  transform: translateY(var(--dlg-panel-animation));
  opacity: 0.4;
}

.pullup-animation{
  --dlg-panel-animation: 100%;
}

.pulldown-animation{
  --dlg-panel-animation: -100%;
}

.dlg-panel {
  border: solid 2px white;
  grid-area: main;

  /*padding: 1vmin;*/
  background-color: #fffffff0;
  /*opacity: 0.95;*/

  overflow-y: auto;
  pointer-events: all;
  height: fit-content;
  z-index: 5;
}

.dlg-panel div.center{
  font-size: 4vmin;
}

.dlg-panel-full-height {
  height: 100%;
}

.dlg-panel-full-transparent {
  background-color: #ffffff30;
}
</style>
